const allSongs = [
  "'39",
  'A Dozen Red Roses For My Darling',
  'A Human Body',
  'A Kind of Magic',
  "A Winter's Tale",
  'Action This Day',
  'All Dead, All Dead',
  "All God's People",
  'Another One Bites the Dust',
  'Arboria (Planet of the Tree Men)',
  'Back Chat',
  'Battle Theme',
  'Bicycle Race',
  'Bijou',
  'Body Language',
  'Bohemian Rhapsody',
  'Breakthru',
  'Brighton Rock',
  'Bring Back That Leroy Brown',
  'Calling All Girls',
  'Chinese Torture',
  'Coming Soon',
  'Cool Cat',
  'Crash Dive On Mingo City',
  'Crazy Little Thing Called Love',
  'Dancer',
  'Dead On Time',
  'Dear Friends',
  'Death on Two Legs (Dedicated to...)',
  'Delilah',
  'Doing All Right',
  "Don't Lose Your Head",
  "Don't Stop Me Now",
  "Don't Try So Hard",
  "Don't Try Suicide",
  'Dragon Attack',
  "Dreamer's Ball",
  'Drowse',
  'Escape from the Swamp',
  'Execution of Flash',
  'Fat Bottomed Girls',
  'Father to Son',
  'Feelings, Feelings',
  'Fight from the Inside',
  'Flash to the Rescue',
  "Flash's Theme",
  "Flash's Theme Reprise (Victory Celebrations)",
  'Flick of the Wrist',
  'Football Fight',
  'Friends Will Be Friends',
  'Fun It',
  'Funny How Love Is',
  'Get Down, Make Love',
  "Gimme the Prize (Kurgan's Theme)",
  'God Save the Queen',
  'Good Company',
  'Good Old-Fashioned Lover Boy',
  'Great King Rat',
  'Hammer to Fall',
  'Hang On in There',
  'Headlong',
  'Heaven for Everyone',
  'Hijack My Heart',
  "I Can't Live With You",
  'I Go Crazy',
  'I Want It All',
  'I Want to Break Free',
  'I Was Born To Love You',
  "I'm Going Slightly Mad",
  "I'm in Love with My Car",
  "If You Can't Beat Them",
  'Impromptu',
  'In Only Seven Days',
  'In the Death Cell (Love Theme Reprise)',
  'In the Lap of the Gods',
  'In the Lap of the Gods... Revisited',
  'In the Space Capsule (The Love Theme)',
  'Innuendo',
  'Is This the World We Created...?',
  "It's a Beautiful Day",
  "It's a Beautiful Day (Reprise)",
  "It's a Hard Life",
  "It's Late",
  'Jealousy',
  'Jesus',
  'Keep Passing the Open Windows',
  'Keep Yourself Alive',
  "Khashoggi's Ship",
  'Killer Queen',
  'Las Palabras de Amor (The Words of Love)',
  'Lazing on a Sunday Afternoon',
  "Leaving Home Ain't Easy",
  'Let Me Entertain You',
  'Let Me In Your Heart Again',
  'Let Me Live',
  'Liar',
  'Life Is Real (Song For Lennon)',
  'Lily of the Valley',
  'Long Away',
  'Lost Opportunity',
  'Love Kills',
  'Love of My Life',
  "Machines (or 'Back to Humans')",
  'Mad the Swine',
  'Made In Heaven',
  'Man on the Prowl',
  'Marriage of Dale & Ming (And Flash Approaching)',
  "Ming's Theme (In the Court of Ming the Merciless)",
  'Misfire',
  "Modern Times Rock 'n' Roll",
  'More of That Jazz',
  'Mother Love',
  'Mustapha',
  'My Baby Does Me',
  'My Fairy King',
  'My Life Has Been Saved',
  'My Melancholy Blues',
  'Need Your Loving Tonight',
  'Nevermore',
  'No-One But You (Only the Good Die Young)',
  "Now I'm Here",
  'Ogre Battle',
  'One Vision',
  'One Year of Love',
  'Pain Is So Close to Pleasure',
  'Party',
  'Play the Game',
  'Princes of the Universe',
  'Procession',
  'Put Out the Fire',
  'Radio Ga Ga',
  'Rain Must Fall',
  'Ride the Wild Wind',
  'Rock It (Prime Jive)',
  'Sail Away, Sweet Sister',
  'Save Me',
  'Scandal',
  'Seaside Rendezvous',
  "See What a Fool I've Been",
  'Seven Seas of Rhye',
  'She Makes Me (Stormtrooper in Stilettoes)',
  'Sheer Heart Attack',
  'Sleeping on the Sidewalk',
  'Some Day, One Day',
  'Somebody to Love',
  'Son & Daughter',
  'Soul Brother',
  'Spread Your Wings',
  'Staying Power',
  "Stealin'",
  'Stone Cold Crazy',
  'Sweet Lady',
  'Tear It Up',
  'Tenement Funster',
  'Teo Torriatte (Let Us Cling Together)',
  "Thank God It's Christmas",
  "The Fairy Feller's Master-Stroke",
  'The Hero',
  'The Hitman',
  'The Invisible Man',
  'The Kiss (Aura Resurrects Flash)',
  'The Loser in the End',
  'The March of the Black Queen',
  'The Millionaire Waltz',
  'The Miracle',
  'The Night Comes Down',
  "The Prophet's Song",
  'The Ring (Hypnotic Seduction of Dale)',
  'The Show Must Go On',
  'The Wedding March',
  'These Are the Days of Our Lives',
  'Tie Your Mother Down',
  'Too Much Love Will Kill You',
  'Track 13',
  'Under Pressure',
  "Vultan's Theme (Attack of the Hawk Men)",
  'Was It All Worth It',
  'We Are the Champions',
  'We Will Rock You',
  'White Man',
  'White Queen (As It Began)',
  'Who Needs You',
  'Who Wants to Live Forever',
  'Yeah',
  'You And I',
  "You Don't Fool Me",
  'You Take My Breath Away',
  "You're My Best Friend"
]

export { allSongs }
